<footer class="footer py-3" *ngIf="globalItems">
    <nav class="navbar">
        <div class="container">
            <a class="brand" routerLink="/" (click)="route('')" *ngIf="globalItems?.attributes?.siteName">{{ globalItems!.attributes!.siteName }}</a>
        <ul class="nav" *ngIf="globalItems?.attributes?.links">
            <li class="nav-item" *ngFor="let links of globalItems?.attributes?.links ">
                <a class="nav-link" href="{{ links.link }}" target="_blank"><i [ngClass]="buildClass(links.platform)"></i></a>
            </li>
        </ul>
        </div>
    </nav>
  </footer>