import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ethos',
  templateUrl: './ethos.component.html',
  styleUrls: ['./ethos.component.scss']
})
export class EthosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
