<section class="hero d-flex flex-column flex-lg-row">
    <div class="hero__content hero--half d-flex align-items-end justify-content-center py-5 py-lg-0 order-2 order-lg-1">
        <div class="hero__blurb" *ngIf="pageData?.blurb">
            <markdown [data]="pageData.blurb"></markdown>
        </div>
    </div>
    <div class="hero--half hero__image-container order-1 order-lg-2">
        <img src="{{ buildImagePath(pageData?.bannerImage?.data?.attributes?.url) }}" class="img-fluid" [attr.alt]="pageData?.bannerImage?.data?.attributes?.alternativeText" class="img-fluid hero__image" />
    </div>
</section>
<section class="experience__section">
    <div class="container">
        <div class="row">
            <div class="experience col-12 col-md-6" *ngFor="let group of pageData.group">
                <h3 *ngIf="group">{{ group.title }}</h3>
                <div class="experience__group">
                    <ul class="stickies" *ngIf="group">
                        <li class="stickies__single" *ngFor="let sticky of group.highlights">{{ sticky.highlight }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>