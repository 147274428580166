<section class="hero d-flex flex-column flex-lg-row">
    <div class="hero__content hero--half d-flex align-items-end justify-content-center py-5 py-lg-0 order-2 order-lg-1">
        <div class="hero__blurb">
            <h2 class="hero__title" *ngIf="pageData?.header">{{ pageData.header }}</h2>
            <div class="lead" *ngIf="pageData?.blurb">
                <markdown [data]="pageData.blurb"></markdown>
            </div>
            <section class="contact">
                <form class="contact__form" [formGroup]="contactForm" (ngSubmit)="onSubmit()"  *ngIf="!onSuccess && !onSubmitFail">
                    <div class="contact__form-control contact__form-control--full">
                        <input type="text" formControlName="fullName" placeholder="FULL NAME*">
                    </div>
                    <div class="contact__form-control contact-form--email contact__form-control--half">
                        <input type="text" formControlName="email" placeholder="EMAIL*">
                    </div>

                    <div class="contact__form-control contact__form--subject contact__form-control--half">
                        <input type="text" formControlName="subject" placeholder="SUBJECT*">
                    </div>

                    <div class="contact__form-control contact__form-control--full">
                        <textarea type="text" formControlName="message" placeholder="MESSAGE*"></textarea>
                    </div>

                    <div class="contact__form-control d-flex justify-content-end">
                        <button class="contact__form--submit button" type="submit" [ngClass]="{'disabled': !contactForm.valid}" [disabled]="!contactForm.valid"><i class="bi bi-arrow-right"></i>
                        </button>
                    </div>
                </form>
                <div>
                    <div class="contact__response mb-5" *ngIf="onSuccess || onSubmitFail">
                        <markdown [data]="pageData.thankYouText" *ngIf="onSuccess"></markdown>
                        <markdown [data]="pageData?.formSubmitFailText" *ngIf="onSubmitFail"></markdown>
                    </div>
                    <!-- <p (click)="route('')" style="cursor: pointer">Click Me to go somewhere</p> -->
                </div>
            </section>
        </div>
    </div>
    <div class="hero--half hero__image-container order-1 order-lg-2">
        <img src="{{ buildImagePath(pageData?.bannerImage?.data?.attributes?.url) }}" class="img-fluid" [attr.alt]="pageData?.bannerImage?.data?.attributes?.alternativeText" class="img-fluid hero__image" />
    </div>
</section>
