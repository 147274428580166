<div class="work" *ngIf="workData">
<section class="work__title">
    <h1 class="d-none">{{ workData.titleTop }} {{ workData.titleBottom }}</h1>
    <div class="work__title-section work__title-section--top d-flex">
        <h2 class="align-self-end">{{ workData.titleTop }}</h2>
    </div>
    <div class="work__title-section work__title-section--bottom">
        <div class="work__title-section-container container-fluid d-flex flex-column h-100">
            <div class="row flex-shrink 0">
                <h2>{{ workData.titleBottom }}</h2>
                <div class="col-12 col-md-7">
                    <h3>{{ workData.subhead }}</h3>
                </div>
            </div>
            <div class="row align-self-end mt-auto">
                <div class="col-12 col-md-6 ms-auto work__blurb"><p class="lead">{{ workData.blurb }}</p></div>
            </div>
        </div>
    </div>
</section>
<section class="work__details pb-5">
    <div class="container">
        <div
            *ngFor="let workrow of workData.workRow;
            let index = index"
            class="row d-flex justify-content-between work__detail"
            [ngClass]="{ 'work__detail--left': 0 === index % 2, 'work__detail--right': 0 !== index % 2}">
            <div class="col-12 col-sm-6">
                <div class="work__media">
                <div class="work__media-outline position-absolute"></div>
                <img src="{{ buildImagePath(workrow?.primaryImage?.data?.attributes?.url) }}"
                 alt="{{ workrow.primaryImage.data.attributes.alternativeText }}"
                 class="img-fluid work__media-image">
                 <button
                    *ngIf="workrow.mediaType === 'YouTube Video'"
                    type="button"
                    class="btn btn-transparent position-absolute top-50 start-50 translate-middle work__media-action"
                    data-bs-toggle="modal"
                    [attr.data-bs-target]="'#video-' + workrow.id"><i class="bi bi-play-fill"></i>
                </button>
                <a href="{{ buildPath(workrow.pdf.data?.attributes?.url) }}"
                    *ngIf="workrow.mediaType === 'PDF' && workrow?.pdf?.data !== null"
                    target="_blank"
                    class="btn btn-transparent position-absolute top-50 start-50 translate-middle work__media-action"
                >Click to View PDF
                </a>
                 <span class="work__media-index">
                    0{{ index + 1 }}
                 </span>
                 </div>
            </div>
            <div class="col-12 col-sm-5 mt-4 mt-sm-0 d-flex align-items-center">
                <markdown [data]="workrow.blurb"></markdown>
            </div>
            <div
                *ngIf="workrow.mediaType === 'YouTube Video'"
                class="modal fade" [attr.id]="'video-' + workrow.id" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="youtube__responsive-wrapper">
                            <youtube-player [videoId]="getHash(workrow.youtubeUrl)"></youtube-player>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>

<app-cards [excludedId]="workDataId"></app-cards>