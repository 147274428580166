<section class="hero">
    <img src="{{ buildImagePath(pageData?.hero?.data?.attributes?.url) }}" class="img-fluid" [attr.alt]="pageData?.hero?.data?.attributes?.alternativeText" class="img-fluid hero__image" />
</section>
<section class="container py-5 py-md-5 power-words">
    <div class="row power-words__animated">
        <h2 class="power-words__word">{{ _powerWord$ | async }}</h2>
    </div>
    <div class="row py-md-5 d-flex">
        <div class="col-12 col-md-6 order-1 order-md-2">
            <h3 class="power-words__word-end">{{ pageData?.powerWordEnd }}</h3>
        </div>
        <div class="col-12 mt-3 mt-md-0 col-md-6 col-lg-5 me-lg-auto order-2 order-md-1 d-flex align-items-end">
            {{ pageData?.blurb }}
        </div>
    </div>
</section>
<section class="work-animation">
    <img src="{{ buildImagePath(pageData?.workSectionImage?.data?.attributes?.url) }}" class="img-fluid w-100" [attr.alt]="pageData?.workSectionImage?.data?.attributes?.alternativeText" />
    <app-cards></app-cards>
</section>
<section class="testimonials d-md-flex position-relative">
    <div class="testimonials__bg testimonials__bg--dark d-md-flex align-items-md-center">
        <div class="testimonials__blurb testimonials__blurb--left me-auto" *ngIf="pageData?.leftBlurb"><markdown [data]="pageData.leftBlurb"></markdown></div>
    </div>
    <div class="testimonials__bg testimonials__bg--light d-md-flex align-items-md-center">
        <div class="testimonials__blurb testimonials__blurb--right ms-auto" *ngIf="pageData?.rightBlurb">
            <markdown [data]="pageData.rightBlurb"></markdown></div>
    </div>
    <div class="d-none d-md-block circle flex-shrink-0 position-absolute top-50 start-50 translate-middle">
        <div class="circle__inner"></div>
    </div>
</section>